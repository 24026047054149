import type { UiLabel } from 'client-lib/src/lib/utils/helpers/types';
import type { I18nError } from './constants';

/**
 * Contact that has at least these values to determine thier SMS status
 * @typedef {object} ContactSMSSettings
 * @property {boolean} announcementsOptOut
 * @property {boolean} promotionalBroadcastOptOut
 * @property {SMSStatus[]} smsStatus
 */

/**
 * Group object for user
 * @typedef {object} Group
 * @property {string} id
 * @property {string} name
 * @property {string} accountId
 * @property {string} timezone
 * @property {boolean} awayMessageEnabled
 * @property {string} awayMessageTemplate
 * @property {string} awayMessageText
 * @property {number} autoReturnThreadsDelayMins
 * @property {boolean} autoReturnThreadsEnabled
 */

/**
 * Type for i18n when it's passed as a prop
 */
export interface I18NType {
  t: (
    key: string,
    args?: {
      defaultValue: string;
      [key: string]: string | number;
    }
  ) => string;
}

/**
 * Types for App Theme
 */

export enum FontSizeTheme {
  default = 'default',
  large = 'large',
}

export enum ModeTheme {
  light = 'light',
  dark = 'dark',
}

export type Theme = {
  fontSize: FontSizeTheme;
  mode: ModeTheme;
};

/**
 * Type for CurrentUser in AppState
 */
export type CurrentUser = {
  accountId: string;
  accountPolicies: string[];
  accountPoliciesQueried: string[];
  firstName: string;
  fontSize: string;
  groupIds: string[];
  groupPolicies: string[];
  language: string;
  theme: string;
  userId: string;
};

export type PhoneNumber = {
  carrierType: string | null;
  contactId: string;
  id: string;
  label: string;
  number: string;
  primary: boolean;
  __typename: 'PhoneNumber';
};

export type DailyHours = {
  closeAt: string | null;
  openAt: string | null;
  __typename: 'DailyHours';
};

export type Address = {
  addressLine1: string;
  addressLine2: string;
  country: string;
  locality: string;
  administrativeDistrictLevel1: string;
  postalCode: string;
};

/**
 * Type for Group in AppState
 */
export type Group = {
  id: string;
  accountId: string;
  name: string;
  timezone: string;
  address: Address;
  contact: {
    id: string;
    groupId: string;
    phoneNumbers: PhoneNumber[];
    __typename: 'GroupContact';
  };
  businessHours: {
    monday: DailyHours;
    tuesday: DailyHours;
    wednesday: DailyHours;
    thursday: DailyHours;
    friday: DailyHours;
    saturday: DailyHours;
    sunday: DailyHours;
    __typename: 'WeeklyHours';
  };
  autoReturnThreadsDelayMins: number;
  autoReturnThreadsEnabled: boolean;
  awayMessageEnabled: boolean;
  awayMessageTemplate: string;
  awayMessageText: string | null;
  groupConfig: {
    feedbackInterval: string | null;
  };
  member_count: number;
  __typename: 'Group';
};

export type Announcement = {
  audienceInfo?: { reachableAudienceCount: number };
  attachments?: object[];
  failedCount?: number;
  link?: string | null;
  clickCount: number | null;
  id: string;
  insertedByUser: { __typename: 'User'; firstName: string; lastName: string };
  numThreads: number;
  outboundChannel: {
    __typename: 'Channel';
    id: string;
    group: Partial<Group>;
    smsConfig: {
      __typename: 'SmsConfig';
      smsCarrier: string;
    };
  };
  responseCount: number;
  sendCompletedAt: string;
  sendStartedAt: string;
  sentCount: number;
  subject: string;
  unsubscribeCount: number;
  updatedAt: string;
  dueAt: string;
  endsAt?: string;
  __typename: 'Announcement';
  repeats: string;
};

export interface Attachment {
  data: string;
  originalFilename: string;
  type: string;
  id: string;
  size: number;
}

export type ImageToUpload = {
  nodeKey: string;
  url?: string | undefined;
  error?: I18nError | null;
  attachment: Attachment;
};

/**
 * Type for AppState in useSelector
 */
export interface AppState {
  accountData: {
    account: {
      ff_broadcast_list_improvements: boolean;
      ff_email: boolean;
      ff_rich_text_email_formatting: boolean;
      name: string;
    };
    allGroups: Array<Group>;
  };
  applicationState: {
    userLoadedFromDistributor: boolean;
    applicationLoaded: boolean;
  };
  session: {
    currentUser: CurrentUser;
    feedbackActiveGroupIds: string[];
    threadsActiveGroupIds: string[];
    [key: string]: string | boolean | string[] | object;
  };
  general: {
    announcementFilter: string;
    announcementStatus: string;
    contactSlideoutAutofocusField: string;
    snackAutoHide: boolean;
    snackbarOpen: boolean;
    snackButtonLink: string;
    snackMessage: string;
    snackType: string;
    triggerRefetchBroadcast: boolean;
  };
  createSection: {
    createTask: {
      id: string;
    };
  };
  uploadModal: {
    modalOpen: boolean;
    annotationsModalOpen: boolean;
    uploadProgressBarOpen: boolean;
    uploadFailed: boolean;
    attachment: File | null;
    attachments: Attachment[];
    openUnblockModal: boolean;
    numberToBeUnblock: string;
    annotationAttachmentIndex: 0;
  };
  label: {
    createLabelState: boolean;
    editLabelState: boolean;
  };
  editCustomer: {
    triggerEditContactLabels: boolean;
  };
}

export enum SMS_STATUS {
  landline = 'SUSPECTED_LANDLINE',
  not_in_service = 'NOT_IN_SERVICE',
}

export enum AUTH_PROVIDER {
  web = 'web',
  saml = 'saml',
  microsoft = 'microsoft',
  cognito = 'cognito',
}

export enum WEEK_DAYS {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

/**
 * Type for PageInfo in Paginated Queries
 */
export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalCount: number;
};

/**
 * Type for Companies, also known as CustomerAccounts
 */
export type CustomerAccount = {
  __typename: 'CustomerAccount';
  accountNumber?: string;
  emailAddress?: string;
  id: string;
  name: string;
  notes?: string;
  notesUpdatedAt?: Date;
  phoneNumber?: string;
  workOrderCount: number;
  contacts: CustomerAccount[];
};

/**
 * Type for Contact Labels, also known plainly as Labels
 */
export type Label = {
  __typename: 'Label';
  color?: string;
  description?: string;
  id: string;
  insertedAt: string;
  name: string;
  numberOfContacts: number;
  updatedAt?: string;
};

/**
 * Type for Broadcast Lists, also known as Tags
 */
export type Tag = {
  id: string;
  name: string;
  __typename: 'Tag';
};

/**
 * Type for Contacts, also known as CustomerContacts
 */
export type CustomerContact = {
  __typename: 'CustomerContact';
  account: CustomerAccount;
  announcementsOptInAt?: Date;
  announcementsOptOut: boolean;
  apiOptOut: boolean;
  blockedChannels: string[];
  conversationsOptOut: boolean;
  emailAddress: string;
  emailsOptOut: boolean;
  faxNumber?: string;
  faxOptOut: boolean;
  firstName: string;
  groupIds: string[];
  id: string;
  jobTitle?: string;
  labels: Label[];
  lastName: string;
  name?: string;
  notes?: string;
  emailAddresses?: object[];
  notesUpdatedAt?: Date;
  phoneNumber?: string;
  prioritizeRep: boolean;
  promotionalBroadcastOptOut: boolean;
  repUser?: string;
  smsStatus: SMS_STATUS[];
  tags: Tag[];
};

export interface CreateThreadState {
  contact: CustomerContact | null;
  contactPhoneNumber: string;
  messageInputValue: string;
  attachment: Attachment | null;
  senderGroup: UiLabel | null;
  additionalRecipients: CustomerContact[];
  taskId?: string;
  templateId?: string;
}

export type AccountRep = {
  value: string;
  id: string;
  lastSelectedName: string;
  selectedUser: Record<string, unknown>;
};

export type CreateCustomerGroup = {
  value?: string;
  text: string;
};

export type CreateCustomer = {
  firstName: string;
  id: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  welcomeMessage: boolean;
  company: {
    value: string;
    text: string;
    addCompanySuggestion: boolean;
  };
  accountPhoneNumber: string;
  accountEmail: string;
  accountNumber: string;
  group: {
    value: CreateCustomerGroup[];
    text: string;
  };
  accountRep: {
    value: string;
    id: string;
    lastSelectedName: string;
    selectedUser: Record<string, unknown>;
  };
  prioritizeRep: boolean;
  updateContact: boolean;
  updateContactType: string;
  isMergeContact: boolean;
  newPhoneNumber: string;
  newFaxNumber: string;
  newEmail: string;
  mergeOn: string;
  mergeContactId?: string;
  phoneSelectValue: string;
  faxSelectValue?: string;
  emailSelectValue?: string;
  groupIds?: string[];
};

export enum ThreadTypeEnum {
  sms = 'sms',
  email = 'email',
  fax = 'fax',
}

// Please add to this type
export type ExternalContact = {
  emailAddress?: string | null;
  fisrtName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  jobTitle?: string | null;
};

// This is only a start to a _very_ complex type.
// PLEASE add to it!
export type Thread = {
  accountId: string;
  groupId: string;
  id: string;
  type: ThreadTypeEnum;
  subject: string | null;
  externalContact: ExternalContact;
  otherExternalContacts?: ExternalContact[];
};
